.ComuPage .HeaderTextImg {
  position: absolute;
  right: 15%;
  max-width: 555px;
  width: 65%;
  top: 30vh;
}

.ComuPage ul {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  line-height: initial;
  list-style-image: url('../assets/comunicacion/bullet.png');
}

.ComuPage li {
  margin-bottom: 5px;
  font-size: 11pt;
}

.ComuPage .InfoTextImg {
  width: 90%;
  max-width: 892px;
}

.ComuSection {
  position: relative;
  width: inherit;
  transition: transform 0.75s;
}

.HiddenUp .ComuSection, .HiddenDown .ComuSection {
  transform: scale(1.3);
}

.ComuSection > div {
  display: inline-block;
  vertical-align: top;
  text-align: start;
}

.Sen > .ComuSectionImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (orientation: landscape) {
  .ComuPage .Title {
    height: 30vh;
  }

  .ComuPage .BodyUnderTitle {
    height: 70vh;
  }

  .ComuSectionBullets {
    border-left: 2px solid rgba(47, 215, 194, 255);
    padding-left: 10px;
  }

  .Piz {
    text-align: end;
  }

  .Piz > .ComuSectionTitle > img {
    width: 90%;
    margin-right: 10%;
  }

  .Piz > .ComuSectionBullets{
    margin-right: 5vw;
  }

  .Piz > .ComuSectionImage {
    border-left: 10px solid #7cfc00;
  }

  .Sen {
    height: 200px;
    margin-bottom: 70px;
    text-align: start;
  }

  .Sen > .ComuSectionTitle {
    margin-left: 40px;
    width: 35vw;
    max-width: 650px;
  }

  .Sen > .ComuSectionTitle > img {
    width: 90%;
  }

  .Sen > .ComuSectionImage {
    border-right: 10px solid #7cfc00;
    width: 30%;
    max-width: 360px;
  }

  .Otr {
    height: 192px;
  }

  .Otr > .ComuSectionTitle {
    padding-right: 30px;
  }

  .Otr > .ComuSectionImage {
    border-left: 10px solid #7cfc00;
  }

  .Otr > .ComuSectionBullets {
    width: 350px;
  }
}

@media (orientation: portrait) {
  .ComuPage .HeaderImg {
    object-position: 25% 50%;
  }

  .ComuPage .HeaderTextImg {
    top: initial;
    right: 10vw;
    width: 75%;
    bottom: 8vh;
  }

  .ComuPage .Title {
    height: 15vh;
  }

  .ComuPage .BodyUnderTitle {
    height: 75vh;
  }

  .ComuSectionBullets {
    border-top: 2px solid rgba(47, 215, 194, 255);
  }

  .ComuSectionImage {
    border-top: 8px solid #7cfc00;
    width: 80vw;
  }

  .ComuSectionTitle {
    width: 65vw;
  }

  .ComuSectionTitle > img {
    width: 100%;
  }

  .Piz > .ComuSectionTitle {
    width: 100vw;
    text-align: center;
  }

  .Piz > .ComuSectionTitle > img {
    width: 40vw;
  }

  .Otr > .ComuSectionTitle {
    width: 100vw;
    text-align: center;
  }

  .Otr > .ComuSectionTitle > img {
    width: 35vw;
  }
}

@media only screen and (max-width: 360px) {
  .ComuPage li {
    margin-bottom: 4px;
    font-size: 0.8em;
  }
}
