.App {
  text-align: center;
}

.Page {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ContentDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Prevent buttons from being selectable */
button {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}
