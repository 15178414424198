.EndoPage .HeaderTextImg {
  position: absolute;
  right: 10%;
  max-width: 445px;
  width: 60%;
  top: 35vh;
}

.EndoPage .Title {
  height: 20vh;
}

.EndoPage .BodyUnderTitle {
  height: 80vh;
}

.EndoPage .InfoTextImg {
  max-height: 15vh;
  max-width: 960px;
}

.ComoTile.Endo0 {
  width: 25%;
  height: 23vh;
}

.ComoTile.Endo1 {
  width: 33.33%;
  height: 34vh;
}

.ComoTileGroupHalf .ComoTile.Endo0 {
  width: 40%;
  height: 30vh;
}

.ComoTileGroupHalf .ComoTile.Endo1 {
  width: 80%;
  height: 30vh;
}

.ComoTileGroupHalf .ComoTile.Endo2 {
  width: 80vw;
  height: calc(100vw * 37/80);
}

.ComoTileGroup.Endo {
  width: calc(100vh * 0.8 * 2);
}

.ComoTile.Endo0 > img {
  object-position: 50% 50%;
}

.ComoTile.Endo0 > div > span {
  font-size: 10pt;
}

.ComoTile.Endo > div > span {
  font-size: 11pt;
}

@media (orientation: portrait) {
  .EndoPage .Title {
    height: 15vh;
  }

  .EndoPage .BodyUnderTitle {
    height: 74vh;
  }

  .EndoPage .InfoTextImg {
    max-width: 90vw;
  }

  .EndoPage .HeaderImg {
    object-position: 0 50%;
  }

  .EndoPage .HeaderTextImg {
    right: initial;
    top: initial;
    left: 10vw;
    bottom: 15vh;
  }
}
