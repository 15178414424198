.Form {
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: visible;
  margin: 5px;
}

.FormField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.FieldRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RowLabel {
  align-self: flex-start;
  margin-right: 10px;
}

.RowInput {
  align-self: flex-start;
}

.FieldError {
  color: red;
  text-align: left;
}

.FormError {
  color: red;
}

.SubmitButton {
  margin-top: 5px;
  width: 60px;
  align-self: flex-start;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}
