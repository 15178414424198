.ContactoPage .HeaderTextImg {
  position: absolute;
  right: 15%;
  max-width: 555px;
  width: 65%;
  top: 30vh;
}

.StepSection {
  text-align: center;
  display: inline-block;
  margin-left: calc(50% - 330px);
  min-width: 600px;
}

.StepSection ul {
  display: inline-block;
  text-align: left;
  margin-top: 5px;
  list-style-image: url('../assets/common/checkbox_grey_green.png');
  line-height: initial;
  border-left: 2px solid #2fd7c2;
  width: 500px;
}

.StepSection li {
  margin-bottom: 15px;
  font-size: 11pt;
  position: relative;
}

.PasoList {
  vertical-align: top;
  display: inline-block;
  width: 80px;
  padding-top: 5px;
}

.StepSection li span {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  position: absolute;
  top: 0px;
  left: -105px;
}

.DatosSection {
  background-color: var(--green-dark-color);
}

.DatosSection table {
  display: inline-block;
  text-align: left;
  margin-left: calc(50% - 330px + 80px - 25px);
  width: 500px;
}

.DatosSection table img {
  filter: grayscale() brightness(1000%);
  margin-right: 25px;
}

.ContactForm {
  display: inline-block;
  width: 60%;
  text-align: right;
}

.ContactForm input {
  width: 100%;
}

.ContactForm .FormField:nth-child(3) {
  margin-bottom: 40px;
}

.ContactForm textarea {
  width: 100%;
}

.ContactForm .SubmitButton {
  width: auto;
  background-color: var(--aqua-color);
  color: white;
  font-weight: bold;
  border: none;
  padding: 6px;
}

.ContactForm .SubmitButton:disabled {
  background-color: grey;
}

.aqua {
  color: #2fd7c2;
}

.CampoText {
  line-height: 40px;
  color: #2fd7c2;
  float: left;
  font-style: italic;
}

@media (orientation: portrait) {
  .ContactoPage .HeaderTextImg {
    top: initial;
    bottom: 10vh;
    left: 15vw;
    filter: drop-shadow(0px 0px 3px #3334);
  }

  .ContactoPage .HeaderImg {
    object-position: 28% 50%;
  }

  .ContactoPage .Title {
    height: 10vh;
  }

  .ContactoPage .InfoTextImg {
    max-width: 80vw;
  }

  .StepSection {
    width: 90vw;
    min-width: initial;
    margin-left: initial;
  }

  .StepSection ul {
    width: calc(80vw - 80px - 43px);
  }

  .PasoList {
    width: 80px;
  }

  .DatosSection table {
    margin-left: initial;
    width: 90vw;
    margin-left: 10vw;
  }
}