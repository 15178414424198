.QuestionDisplay {
    text-align: start;
    padding-bottom: 20px;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    margin-top: -1px;
}

.QuestionTextDisplay {
    background-color: #EEE;
}

.ql-tooltip {
    height: 0px;
}

th {
    font-weight: normal;
}

.QuestionCreateDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CreateQuestionForm {
    min-width: 600px;
    max-width: 800px;
}

.QuestionCreateActions input, .QuestionCreateActions button{
    background: #282c34;
    border: none;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    font-weight: bold;
    color: white;
    padding: 6px;
    margin: 6px;
    width: auto;
}