.EntrenadosPage .HeaderTextImg {
  position: absolute;
  right: 10%;
  top: 40vh;
  width: 65%;
  max-width: 460px;
  filter: drop-shadow(0px 0px 5px #333);
}

.EntrenadosTitleHolder {
  position: relative;
  width: 100%;
}

.EntrenadosTitle {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: 50px;
  transition: transform 0.75s;
}

.HiddenUp .EntrenadosTitle, .HiddenDown .EntrenadosTitle {
  transform: scale(1.5);
}

.EntrenadosTitle:nth-child(2) {
  left: 50%;
}

.EntrenadosTitle.Lime1 {
  padding-top: 10px;
}

.EntrenadosTextLink > img {
  filter: drop-shadow(0px 0px 5px #333);
}

.EntrenadosContent {
  display: block;
  width: inherit;
}

.EntrenadosContent div, .EntrenadosContentX img {
  display: inline-block;
}

.EntrenadosSection {
  width: 50%;
  vertical-align: top;
  margin-top: 80px;
}

.EntrenadosContent > :nth-child(1) .NumberList {
  padding-bottom: 20px;
  border-bottom: 2px solid #d8d8d8;
}

.EntrenadosIcon {
  margin-right: 20px;
  transition: transform 0.2s;
}

.EntrenadosIcon:hover {
  transform: scale(1.1);
}

.EntrenadosSection > :nth-child(2) {
  width: 70%;
}

.EntrenadosSection p {
  text-align: left;
  margin-top: 0px;
}

.EntrenadosSection > div {
  vertical-align: middle;
}

.NumberList {
  text-align: left;
  width: 100%;
}

.NumberList ul {
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  list-style-image: url('../assets/common/checkbox.png');
  line-height: initial;
  width: 70%;
  padding-inline-start: 20px;
}

.OfImage {
  opacity: 0.4;
  transition: transform 0.75s;
}

.HiddenUp .OfImage, .HiddenDown .OfImage {
  transform: scale(1.3);
}

.NumberList.Aqua ul {
  text-align: left;
  margin-top: 5px;
  list-style-image: url('../assets/common/checkbox_grey_aqua.png');
  line-height: initial;
  width: 70%;
  padding-inline-start: 20px;
}

.NumberList li, .BottomList li {
  font-size: 11pt;
  margin-bottom: 5px;
}

.BottomList {
  width: 100%;
  background-color: lightgray;
  min-height: 40px;
}

.BottomList.Green {
  background-color: var(--aqua-color);
}

.BottomListTitleHolder {
  width: 100%;
  text-align: left;
}

.BottomListTitle {
  color: var(--aqua-color);
  margin-left: 30%;
}

.BottomList ul {
  margin-left: 30%;
  text-align: left;
  margin-top: 5px;
  list-style-image: url('../assets/common/checkbox_green_white.png');
  line-height: initial;
  padding-inline-start: 20px;
}

.Circle {
  border-radius: 50%;
  background-color: var(--green-light-color);
}

.Size1 {
  width: 10vh;
  height: 10vh;
}
.Size2 {
  width: 15vh;
  height: 15vh;
}
.Size3 {
  width: 20vh;
  height: 20vh;
}
.Size4 {
  width: 30vh;
  height: 30vh;
}
.Size5 {
  width: 40vh;
  height: 40vh;
}

@media (orientation: landscape) {
  .EntrenadosSection > :nth-child(1) {
    width: 30%;
    text-align: right;
  }
}

@media (orientation: portrait) {
  .EntrenadosPage .HeaderTextImg {
    right: initial;
    top: initial;
    bottom: 15vh;
    left: 15vw;
  }

  .EntrenadosSection {
    width: initial;
    vertical-align: top;
    margin-top: 80px;
    align-items: center;
    margin-top: 3vh;
  }

  .EntrenadosTitleHolder {
    align-items: center;
    width: 100vw;
  }

  .EntrenadosTitle {
    display: inline-block;
    position: relative;
  }

  .NumberList {
    width: 80%;
  }

  .NumberList.Aqua ul {
    width: 100%;
  }

  .EntrenadosTitle {
    width: 80vw;
    margin-left: initial;
  }

  .EntrenadosSection p {
    display: inline-block;
    width: 80vw;
    text-align: left;
    margin-top: 0px;
  }

  .OfImage {
    opacity: 0.4;
    width: 90vw;
  }
}
