.NoAnimation * {
  animation: none !important;
  transition: none !important;
}

.Body {
  background-color: white;
  overflow: hidden;
}

.ViewPort {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.PageDivScroller {
  position: fixed;
  width: 100%;
  height: 100vh;
  transition: margin-top 0.75s, margin-left 0.75s;
  overflow: hidden;
}

.PageDivScroller.NoAnimation {
  animation: none !important;
  transition: none !important;
}

.PageDivScroller.HiddenUp {
  margin-top: -100vh;
}

.PageDivScroller.HiddenDown {
  margin-top: 100vh;
}

.PageDivScroller.HiddenLeft {
  margin-left: -100%;
}

.PageDivScroller.HiddenRight {
  margin-left: 100%;
}

.PageIndexDisplay {
  position: absolute;
  z-index: 9999;
  border-radius: 3px;
  transition: opacity 0.75s;
}

.PageIndexDisplay.Hidden {
  opacity: 0;
}

.PageIndexDisplay.Vertical {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.PageIndexDisplay.Horizontal {
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.PageIndexDisplay span {
  display: block;
}

.PageIndexDisplay.Horizontal span {
  display: inline;
  margin: 0 6px;
}

.PageIndexDisplay span.Active {
  color: var(--aqua-color);
}

.PageDiv {
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: hidden;
}

.Title {
  position: relative;
  width: inherit;
  height: 15vh;
  overflow: hidden;
}

.BodyUnderTitle {
  position: relative;
  margin-top: -2vh;
  width: inherit;
  height: 85vh;
  overflow: hidden;
}

.CenterVert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
}

.BottomVert {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

@media (orientation: portrait) {
  .Title {
    margin-top: 6vh;
    height: 8vh;
  }

  .CenterVert {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
  }
}


.HeaderImg {
  width: inherit;
  height: inherit;
  object-fit: cover;
  z-index: -9999;
}

.SocialButtonBar {
  position: fixed;
  right: 0px;
  bottom: 30%;
  transform: translateY(50%);
  z-index: 9999;
}

.SocialButton {
  display: block;
  width: 40px;
  height: 40px;
}

.HomeButton {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
  height: 40px;
  width: 40px;
}

.HomeButton > img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .SocialButton {
    display: block;
    width: 25px;
    height: 25px;
  }

  .HomeButton {
    right: 5px;
    bottom: 5px;
    height: 25px;
    width: 25px;
  }
}
