.AppLogo {
  height: 10vmin;
}

.AppHeader {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.HeaderUserBar {
  position: absolute;
  top: 0%;
  text-align: right;
  min-width: 100vw;
  display: flex;
}

.HeaderButtonBar {
  position: absolute;
  bottom: 0%;
  text-align: right;
  min-width: 100vw;
  padding-bottom: 6px;
}

.HeaderButtonBar a {
  text-decoration: none;
}

.HeaderButtonBar a:hover {
  text-decoration: none;
}

.HeaderButton {
  display: inline;
  background: none;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  color: white;
  padding: 6px;
}

.HeaderButton.Active {
  background: #494e53;
}

.HeaderButton:hover {
  background: #34373b;
}

.HeaderButton:active, .HeaderButton:focus {
  outline: 0;
  border: none;
}

.HelloMessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  color: white;
  padding: 6px;
}

.OverlayDiv {
  position: fixed;
  margin-top: 30px;
  margin-left: 5px;
  background-color: #cad0db;
  border-radius: 5px;
}

.CloseButton {
  outline: 0;
  position: absolute;
  right: 0px;
  border: none;
  margin: 1px;
  background: none;
}