.ExternoPage .HeaderTextImg {
  position: absolute;
  right: 10%;
  max-width: 450px;
  width: 60%;
  top: 45vh;
}

.ComoTile.Externo {
  width: 50%;
  height: 40vh;
}

.ExternoPage .Title {
  height: 30vh;
}

.ExternoPage .BodyUnderTitle {
  height: 70vh;
}

.ExternoPage .InfoTextImg {
  width: 90%;
  max-width: 700px;
}

.MiddleSectionExterno div:nth-child(1) {
  height: 100%;
}

@media (orientation: portrait) {
  .ExternoPage .Title {
    height: 15vh;
  }

  .ExternoPage .BodyUnderTitle {
    height: 74vh;
  }

  .ExternoPage .HeaderTextImg {
    top: initial;
    bottom: 15vh;
  }
}
