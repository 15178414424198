.SurveyHeader {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  background-color: #DDDDDD;
}

.SurveyHeader button, .SurveyHeader a {
  text-decoration: none;
  background: none;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  padding: 6px;
  float: right;
  color: black;
}

.SurveyHeader button.Active, .SurveyHeader a.Active {
  background: #BBBBBB;
}

.SurveyHeader button:hover, .SurveyHeader a:hover {
  background: #CCCCCC;
}

.SurveyHeader button:active, .SurveyHeader button:focus, .SurveyHeader a:active, .SurveyHeader a:focus {
  outline: 0;
  border: none;
}

.ListTable {
  width: 100%;
  border-collapse: collapse;
}

.ListTable tr th {
  background-color: #e5eff3;
  border: 1px solid #e5eff3;
}

.ListTable tr td {
  border: 1px solid #e5eff3;
}

.CreateQuestionButton {
  background: #282c34;
  border: none;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-weight: bold;
  color: white;
  padding: 6px;
  margin-top: 20px;
}

.SurveyInfoDiv {
  display: flex;
  flex-direction: row;
}

.SurveySubmitButton {
  background: #282c34;
  border: none;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  font-weight: bold;
  color: white;
  padding: 6px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.SurveySubmitButton:disabled {
  background: #999999;
  color: lightgray;
}
