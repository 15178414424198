:root {
  --aqua-color: #00ded1;
  --aqua-color-t4: #00ded166;
  --aqua-color-ta: #00ded1dd;
  --green-dark-color: #7cfc00;
  --green-light-color: #c6ff8f;
  --nav-bar-height: 72px;
  --neg-nav-bar-height: -72px;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.NoDrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.NoInteract {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

ul {
  image-rendering: pixelated;
}

.InfoBar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: var(--aqua-color);
  vertical-align: middle;
  z-index: 9999;
}

.InfoBar span, .InfoBar a {
  display: inline-block;
  line-height: 40px; /* Must match HomeInfoBar*/
  vertical-align: middle;
  color: white;
  text-decoration: none;
}

.InfoBar > :nth-child(1) {
  margin-left: 100px;
}

.InfoBar > :nth-child(2) {
  margin-right: 70px;
  float: right;
}

.InfoBar > :nth-child(3) {
  margin-right: 30px;
  float: right;
}

.NavBarHolder {
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
}

.MenuButton {
  position: fixed;
  max-width: 40px;
  max-height: 40px;
  z-index: 101;
  margin: 16px;
}

@media (orientation: portrait) {
  .MenuButton {
    position: fixed;
    max-width: 35px;
    max-height: 35px;
    z-index: 101;
    margin: 10px;
  }
}

.NavBar {
  width: inherit;
  height: var(--nav-bar-height);
  line-height: var(--nav-bar-height);
  background-color: #ffff;
  box-shadow: 0 4px 2px -2px gray;
  opacity: 1;
  margin-top: 0px;
  transition: margin-top 0.5s, opacity 0.5s;
}

.NavBar.Hidden {
  margin-top: var(--neg-nav-bar-height);
  opacity: 0;
}

.NavBar .Logo {
  line-height: var(--nav-bar-height);
  height: 100%;
  margin: 0;
  margin-left: 100px;
  vertical-align: middle;
  float: left;
}

.NavBar .Logo img {
  vertical-align: middle;
  opacity: 0.5;
  height: 60%;
}

.NavBar a {
  line-height: 40px; /* Must match HomeInfoBar*/
  vertical-align: middle;
  color: black;
  margin: 20px;
  text-decoration: none;
  float: right;
}

/* Because of float right, Contacto ends up being 2nd child (last in list when rendering) */
.NavBar > :nth-child(2) {
  margin-right: 70px;
}

.BottomInfoBar {
  width: 100%;
  height: 250px;
  padding-top: 20px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  background-color: white;
  box-shadow: 0px -1px #333;
  transition: bottom 0.5s, opacity 0.5s;
}

.BottomInfoBar.Hidden {
  bottom: -271px;
  opacity: 0;
}

.BottomInfoBar.Hidden .BottomInfoHeader > td {
  height: 10vh;
  vertical-align: top;
}

.BottomInfoBar > div {
  vertical-align: top;
  width: 33%;
  height: 100%;
  display: inline-block;
  text-align: center;
}

.BottomInfoBar table {
  display: inline-block;
  text-align: left;
}

.BottomInfoBar a {
  text-decoration: none;
  color: black;
  transition: transform 0.25s;
}

.BottomInfoBar a:hover, .Clickable:hover {
  font-weight: bold;
}

.BottomInfoBar img {
  opacity: 0.7;
  filter: grayscale();
}

.BottomInfoBar > .Footer {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.Footer img {
  display: inline-block;
  transform: scale(0.8);
}

@media (orientation: portrait) {
  .BottomInfoBar {
    padding-top: 15px;
    height: 77vh;
    z-index: 10000;
  }

  .BottomInfoBar.Hidden {
    bottom: -77vh;
  }

  .BottomInfoBar > div {
    width: 100%;
    height: initial;
    text-align: left;
    margin-left: 10px;
  }

  .BottomInfoBar > .Footer img {
    display: inline-block;
    transform: scale(0.7);
  }

  .BottomInfoBar > .Footer {
    bottom: -10px;
  }
}

.Darken {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0008;
  transition: opacity 0.5s, z-index 0.5s;
  z-index: 99;
  opacity: 1;
}

.Darken.Hidden {
  opacity: 0;
  z-index: -10;
}

.InfoPageBody > img, .InfoPageBody > div {
  display: inline-block;
}

.InfoTextImg {
  margin-top: 60px;
  margin-bottom: 40px;
  opacity: 0.8;
}

@media (orientation: portrait) {
  .InfoTextImg {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.ComoTextImg {
  max-width: 70vw;
  object-fit: contain;
  transition: transform 0.75s;
}

.HiddenUp .ComoTextImg, .HiddenDown .ComoTextImg {
  transform: scale(1.3);
}

.MiddleSection {
  display: inline-block;
  margin-bottom: 20px;
}

.MiddleSection ul {
  text-align: left;
  margin-top: 5px;
  list-style-image: url('../assets/common/checkbox_grey_green.png');
  line-height: initial;
}

.MiddleSection li {
  margin-bottom: 15px;
  font-size: 11pt;
}

.MiddleSection > :nth-child(1){
  vertical-align: top;
  display: inline-block;
  height: inherit;
  width: 350px;
}

.MiddleSection > :nth-child(2){
  vertical-align: top;
  display: inline-block;
  height: inherit;
  border-left: 2px solid #2fd7c2;
}

.ComoDiv {
  width: inherit;
  background-color: #7cfc00;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 4px;
  text-align: center;
  color: white;
}

.BottomImagePair {
  width: inherit;
  height: 50vh;
  overflow: hidden;
  position: relative;
}

.BottomImagePair div {
  width: 0.5%;
  height: inherit;
  display: inline-block;
}

.BottomImagePair img {
  width: 49.75%;
  height: inherit;
  overflow: hidden;
  object-fit: cover;
  vertical-align: top;
}

.SolicitaLink {
  position: absolute;
  width: 240px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  background-color: var(--aqua-color);
  font-weight: bold;
  font-size: 10pt;
  z-index: 98;
  transition: opacity 0.75s;
}

.SolicitaLink:hover {
  background-color: rgba(47, 225, 204, 255);
}

.SolicitaLink.Hidden {
  opacity: 0;
}

@media (orientation: portrait) {
  .SolicitaLink {
    visibility: hidden;
  }
}

.ComoTileGroupHolder {
  width: 100%;
  height: 75vh;
}

.ComoTileGroup {
  display: inline-block;
  width: calc(100vh * 0.8 * 1.6);
  max-width: 95%;
}

.ComoTile {
  width: 50%;
  height: 40vh;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ComoTileGroupHalf {
  margin-top: 10px;
  height: 100%;
}

.ComoTileGroupHalf .ComoTile {
  width: 75%;
  height: 50%;
}

.ComoTile > img {
  width: 100%;
  height: 100%;
  object-position: 0 0;
  object-fit: cover;
  transition: transform 0.2s;
  filter: grayscale();
}

.ComoTile:hover > img {
  transform: scale(1.05);
}

.ComoTile > div {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--aqua-color-t4);
  opacity: 0;
  transition: opacity 0.2s;
}

.ComoTile:hover > div {
  opacity: 1;
}

.ComoTile > div > span {
  width: 94%;
  padding: 3%;
  font-weight: bold;
  text-align: left;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--aqua-color-ta);
  color: white;
}

@media (orientation: portrait) {
  .MiddleSection {
    width: 90%;
  }

  .MiddleSection > :nth-child(1) {
    width: 90%;
  }

  .MiddleSection > :nth-child(1) > img {
    width: 75%;
    max-width: 300px;
  }

  .MiddleSection > :nth-child(2) {
    border-top: 2px solid #2fd7c2;
    border-left: initial;
  }
}

@media only screen and (max-width: 360px) {
  .ComoTile > div > span {
    font-size: 0.8em;
  }

  .BottomInfoBar {
    font-size: 0.8em;
  }

  :root {
    --nav-bar-height: 60px;
    --neg-nav-bar-height: -60px;
  }
}

.PrivacyDialog {
  background-color: white;
  width: 300px;
  height: 400px;
  position: fixed;
  top: calc(50vh - 200px);
  left: calc(50vw - 150px);
  z-index: 9999;
  text-align: center;
}

.PrivacyDialog button {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  background-color: var(--aqua-color);
  font-weight: bold;
  font-size: 1.1em;
  border: none;
  margin-top: 10px;
}

.PrivacyText {
  width: calc(100% - 20px - 12px);
  margin-left: 10px;
  overflow-y: scroll;
  text-align: initial;
  border: 1px solid lightgray;
  padding: 6px;
  height: calc(100% - 86px - 52px);
}

@media only screen and (max-width: 244px) {
  .PrivacyText {
    height: calc(100% - 118px - 52px);
  }
}

@media (orientation: portrait) {
  .PrivacyDialog {
    width: 90vw;
    height: 80vh;
    left: 5vw;
    top: 10vh;
  }
}

@media (orientation: landscape) {
  .PrivacyDialog {
    width: 60vw;
    height: 60vh;
    left: 20vw;
    top: 20vh;
  }
}

/* HEADER Animations */
.HeaderTextImg {
  transition: transform 0.75s;
}

.HeaderTextImg:hover {
  transition: transform 0.25s;
  transform: scale(1.1);
}

.HiddenLeft .HeaderTextImg, .HiddenRight .HeaderTextImg {
  transition: transform 0.75s;
  transform: scale(1.5) translate(-50%, 0%);
}

.HiddenUp .HeaderTextImg, .HiddenDown .HeaderTextImg {
  transition: transform 0.75s;
  transform: scale(1.5) translate(0%, -100%);
}

/* INFO Animations */
.InfoTextImg {
  transition: transform 0.75s;
}

.HiddenUp .InfoTextImg, .HiddenDown .InfoTextImg {
  transform: scale(1.3);
}

/* MiddleSection Animations */
.MiddleSection {
  transition: transform 0.75s;
}

.HiddenUp .MiddleSection, .HiddenDown .MiddleSection {
  transform: scale(1.3);
}

.ScrollAnim {
  transition: transform 0.75s;
}

.HiddenUp .ScrollAnim, .HiddenDown .ScrollAnim {
  transform: scale(1.3);
}

.ScrollAnim2 {
  transition: transform 0.75s;
}

.HiddenUp .ScrollAnim2, .HiddenDown .ScrollAnim2 {
  transform: scale(0.7);
}
