.EstudioPage .HeaderTextImg {
  position: absolute;
  left: 40%;
  width: 55%;
  top: 20vh;
  max-width: 660px;
}

.EstudioPage .InfoTextImg {
  width: 90%;
  max-width: 960px;
}

.EstudioPage .Title {
  height: 20vh;
}

.EstudioPage .BodyUnderTitle {
  height: 80vh;
}

.EstudioTextDiv > img {
  width: 100%;
}

.MiddleSectionEstudios {
  height: 80px;
  line-height: 80px;
}

.ComoTile.Estudio {
  width: 50%;
  height: 40vh;
}

.ComoTileGroupHalf .ComoTile.Estudio {
  width: 80%;
  height: 40vh;
}

.ComoTileGroup.Estudio {
  width: calc(100vh * 0.8 * 1.5);
}

@media (orientation: portrait) {
  .EstudioPage .Title {
    height: 15vh;
  }

  .EstudioPage .BodyUnderTitle {
    height: 74vh;
  }

  .MiddleSectionEstudios {
    height: initial;
    line-height: initial;
  }

  .EstudioPage .HeaderTextImg {
    top: initial;
    left: 10vw;
    width: 75%;
    bottom: 8vh;
  }

  .EstudioPage .HeaderTextImg img {
    width: 100%;
  }

  .EstudioPage .HeaderImg {
    object-position: 10% 50%;
  }
}
