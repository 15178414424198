.ConocenosPage .HeaderTextImg {
  position: absolute;
  right: 10%;
  max-width: 630px;
  width: 70%;
  top: 50vh;
}

.GreenDiv {
  width: inherit;
  font-size: 24px;
  background-color: var(--green-dark-color);
}

.GreenDiv:nth-child(even) {
  background-color: var(--green-light-color);
}

@media (orientation: landscape) {
  .ConocenosPage > .GreenDiv:nth-child(1) {
    height: 80px;
    line-height: 80px;
  }

  .ConocenosPage > .GreenDiv:nth-child(2) {
    height: 50px;
    line-height: 50px;
  }

  .ConocenosPage > .GreenDiv:nth-child(3) {
    height: 50px;
    line-height: 50px;
    margin-bottom: 70px;
  }

}

@media (orientation: portrait) {
  .GreenDiv {
    width: 84vw;
    padding: 2vh 9vw;
    font-size: 1.1em;
  }

  .GreenDiv:nth-child(3) {
    margin-bottom: 8vh;
  }
}

.aquarect {
  width: 100px;
  height: 10px;
  background-color: var(--aqua-color);
}

.BodySection {
  width: inherit;
}

.BodySection > div {
  margin-left: auto;
  margin-right: auto;
}

/* First Section */

.Section1 > div {
  display: inline-block;
  position: relative;
  height: inherit;
  vertical-align: top;
}

.Section1 .aquarect {
  position: absolute;
  right: 0;
  top: -30px;
}

.Section1Header {
  padding-bottom: 15px;
  border-bottom: lightgray 2px solid;
  margin-bottom: 30px;
}

.Section1HeaderLabel {
  font-size: 11pt;
  font-weight: bold;
  color: var(--aqua-color);
  margin-right: 30px;
  opacity: 0.6;
}

.Section1HeaderLabel:nth-child(2) {
  margin-right: 0px;
}

.Section1HeaderLabel.active {
  opacity: 1;
}



.SwitchContentHolder {
  position: relative;
}

.SwitchContent {
  position: absolute;
  top: 0px;
  left: 0px;
  transition: opacity 0.5s;
  transition-delay: 0.5s;
  opacity: 1;
}

.SwitchContent.Hidden {
  opacity: 0;
  transition-delay: 0s;
}

/* Left of First Section */
@media (orientation: landscape) {
  .Section1 {
    width: 930px;
    height: 220px;
    margin-bottom: 70px;
  }

  .ConocenosHolder {
    width: 300px;
    margin-right: 40px;
  }

  .SwitchHolder {
    width: 540px;
    margin-right: 50px;
    text-align: left;
  }

  .Section1Text {
    width: 540px;
    margin-right: 50px;
    text-align: left;
    display: inline-block;
  }

  .ConocenosHolder > img {
    opacity: 0.4;
  }
}

@media (orientation: portrait) {
  .ConocenosHolder {
    width: 70vw;
  }

  .SwitchHolder {
    width: 85vw;
    text-align: left;
  }

  .ConocenosHolder > img {
    width: 100%;
  }

  .Section1HeaderLabel {
    margin-right: 5px;
  }

  .SwitchHolder > img {
    transform: scale(0.8);
  }

  .Section1Text {
    width: 78vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }
}

/* Second section */
.Section2 {
  position: relative;
  text-align: center;
}

.Section2 p {
  margin-bottom: 0px;
}

.NuestroGroup {
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.NuestroGroup img {
  opacity: 0.4;
  display: block;
}

.NuestroGroup .aquarect {
  position: absolute;
  left: 0;
  bottom: 0;
}

.SmallParagraph {
  max-width: 800px;
  display: inline-block;
}

.BigParagraph {
  color: #888;
  font-size: 16pt;
  font-weight: bold;
  max-width: 827px;
  display: inline-block;
}

@media (orientation: landscape) {
  .NuestroGroup {
    max-width: 750px;
    max-height: 100px;
    height: 15vh;
  }

  .NuestroGroup img {
    height: 100%;
    max-width: 100%;
  }

  .Section2 {
    margin-bottom: 4vh;
  }
}

@media (orientation: portrait) {
  .NuestroGroup {
    margin-top: 7vh;
    width: 80vw;
  }

  .NuestroGroup img {
    width: 100%;
    margin-bottom: 1.5vh;
  }

  .NuestroGroup .aquarect {
    height: 7px;
  }

  .SmallParagraph {
    margin-top: 0px;
    width: 90vw;
    font-size: 11pt;
  }

  .BigParagraph {
    width: 83vw;
    font-size: 12.5pt;
    margin-top: 5px;
  }

  .Section2 {
    margin-bottom: 2vh;
  }
}

@media (orientation: landscape) {
  .Section3 > :nth-child(1), .Section3 > :nth-child(2) {
    margin-right: 40px;
  }
}

.Portrait {
  display: inline-block;
  vertical-align: top;
  width: calc(100vh * 0.3);
  height: 45vh;
  margin-bottom: 2vh;
  position: relative;
}

.Portrait img {
  width: 100%;
  height: calc(100% - calc(30px + (45 - 30) * ((100vh - 520px) / (1440 - 520)) * 2));
  object-fit: cover;
}

.NameDiv {
  background-color: var(--aqua-color);
  height: calc(30px + (45 - 30) * ((100vh - 520px) / (1440 - 520)));
  line-height: calc(30px + (45 - 30) * ((100vh - 520px) / (1440 - 520)));
  width: 100%;
  position: absolute;
  bottom: calc(30px + (45 - 30) * ((100vh - 520px) / (1440 - 520)));
  left: 0;
  color: white;
  font-weight: bold;
  font-size: calc(12px + (18 - 12) * ((100vh - 520px) / (1440 - 520)));
}

.DescDiv {
  height: calc(30px + (45 - 30) * ((100vh - 520px) / (1440 - 520)));
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: table-cell;
}

.DescDiv > p {
  font-size: calc(11px + (16 - 11) * ((100vh - 520px) / (1440 - 520)));
  line-height: calc(11px + (16 - 11) * ((100vh - 520px) / (1440 - 520)));
}

.Section4 {
  margin-bottom: 50px;
  text-align: center;
}

.Section4 > img {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;
}

.Section4Sub {
  vertical-align: top;
  display: inline-block;
  text-align: left;
  width: 540px;
}

.Section4Sub div {
  display: inline-block;
  text-align: left;
}

.Section4Sub span {
  font-weight: bold;
  font-size: 13pt;
  color: var(--aqua-color);
}

.Section4Sub ul {
  margin-top: 5px;
  margin-left: -10px;
  list-style-image: url('../assets/common/checkbox_grey_green.png');
  max-width: 300px;
}

.LetterImg {
  margin-right: 10px;
}

.Section4Sub li {
  margin-top: 5px;
  font-size: 11pt;
}

.Section4Icon {
  position: relative;
  transform: scale(0.8);
  vertical-align: top;
  width: 30%;
  transition: transform 0.2s;
}

.Section4Icon:hover {
  transform: scale(1.1);
}

@media (orientation: portrait) {
  .Section4Sub ul {
    margin-left: -10px;
    width: 60vw;
  }

  .Section4Sub {
    width: 87vw;
  }

  .Section4Sub > div {
    width: 72vw;
  }

  .Section4Icon {
    width: 15vw;
  }

  .Section4Sub span {
    font-size: 11pt;
  }

  .LetterImg {
    margin-right: 5px;
    width: 8vw;
  }

  .Section4Sub li {
    font-size: 9.5pt;
  }
}

.Section5 {
  width: inherit;
  margin-bottom: 50px;
}

.Section5 span {
  font-weight: bold;
  color: var(--aqua-color);
  font-size: 13pt;
}

.Section5 div:nth-child(2) {
  background-color: lightgray;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.Section5 > div:nth-child(4) {
  width: inherit;
  background-color: var(--green-dark-color);
  margin-top: 5px;
}

@media (orientation: landscape) {
  .ConocenosPage .BodyUnderTitle {
    height: 85vh;
  }

  .ConocenosPage .Title {
    height: 15vh;
  }

  .Section5 > div:nth-child(4) {
    height: 40px;
    line-height: 40px;
  }
}

@media (orientation: portrait) {
  .ConocenosPage .HeaderTextImg {
    right: initial;
    top: initial;
    width: 70%;
    bottom: 10vh;
    left: 15vw;
  }

  .ConocenosPage .Title img {
    width: 80vw;
  }

  .ConocenosPage .Title {
    height: 15vh;
  }

  .ConocenosPage .BodyUnderTitle {
    height: 80vh;
  }

  .Section5 > div:nth-child(4) {
    width: 84vw;
    padding: 2vh 8vw;
  }
}
