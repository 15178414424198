/* HEADER */
.LogoImg {
  position: absolute;
  left: 23%;
  height: 50vh;
  object-fit: contain;
  top: 18vh;
  transition: opacity 0.75s, transform 0.75s;
}

.HiddenLeft .LogoImg, .HiddenRight .LogoImg {
  transition: opacity 0.75s, transform 0.75s;
  transform: scale(1.5) translate(-50%, 0%);
}

.HiddenUp .LogoImg, .HiddenDown .LogoImg {
  transition: opacity 0.75s, transform 0.75s;
  transform: scale(1.5) translate(0, -50%);
}

.HomeHeaderQuote {
  position: absolute;
  width: 605px;
  right: 0;
  bottom: 15vh;
}

.Quote0Img {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Quote0Bck {
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HomeHeaderImg {
  object-position: 0 50%;
}

@media (orientation: portrait) {
  .LogoImg {
    width: 50vw;
    right: 10vw;
    left: initial;
    top: 10vh;
  }

  .HomeHeaderQuote {
    width: 100%;
    max-width: 600px;
  }

  .Quote0Img {
    width: 80%;
    transform: translate(-55%, -50%);
  }

  .Quote0Bck {
    width: 100%;
    transform: translate(-55%, -50%);
  }
}

/* SOLUTIONS PAGES */

.SolutionBox {
  display: inline-block;
  text-align: center;
  margin: 20px;
  width: 40%;
  vertical-align: top;
  transition: opacity 0.75s, transform 0.75s;
}

.HiddenUp .SolutionBox, .HiddenDown .SolutionBox {
  transition: opacity 0.75s, transform 0.75s;
  transform: scale(1.5) translate(0, -50%);
}

.SolutionBox div {
  display: inline-block;
  text-align: left;
}

.SolutionBox p {
  display: inline-block;
  width: calc(100% - 26px);
  max-width: 300px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.SolutionInfo > img {
  margin-left: 26px;
}

.Checkbox {
  vertical-align: top;
  margin: 5px;
}

.SolutionInfo {
  position: relative;
  width: 60%;
  max-width: 400px;
}

.SolutionIconHolder {
  position: relative;
  width: 30%;
  max-width: 160px;
  vertical-align: top;
}

.SolutionIconHolder.Estudios {
  width: 25%;
  max-width: 140px;
}

.SolutionIcon {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.75s;
}

.SolutionIcon > img {
  transition: transform 0.2s;
  position: absolute;
}

.SolutionIcon > img:hover {
  transform: scale(1.1);
}

.IconImg {
  width: 100%;
}

.HomePage > .Title > div > img {
  width: 80%;
  max-width: 766px;
}

.HomePage > .Title {
  overflow: initial;
}

.ClientHeader {
  margin-top: 30px;
}

.HomeBody {
  text-align: center;
  width: 100%;
}

.ClientList {
  line-height: 8vh;
  text-align: center;
  display: inline-block;
  transition: transform 0.75s;
}

.HiddenUp .ClientList, .HiddenDown .ClientList {
  transform: scale(1.2);
}

.ClientList img {
  margin-left: 20px;
  margin-right: 20px;
  vertical-align: middle;
  max-width: 240px;
  filter: grayscale();
  object-fit: contain;
  transition: transform 0.2s;
}

.ClientList img:hover {
  transform: scale(1.15);
}

.ClientLogoShort {
  height: 8vh;
  max-height: 140px;
}

.ClientLogoMid {
  height: 6vh;
  max-height: 120px;
}

.ClientLogoLong {
  height: 4vh;
  max-height: 100px;
}

.VerMas {
  float: right;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  background-color: rgba(47, 215, 194, 255);
  font-weight: bold;
}

.VerMas:hover {
  background-color: rgba(47, 225, 204, 255);
}

.QuoteBar1 {
  position: relative;
  width: 100%;
  height: 160px;
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20px;
}

.QuoteBar1 img {
  vertical-align: middle;
}

.QuoteBar1 span {
  color: grey;
  font-size: 18pt;
  font-style: italic;
  margin-top: 30px;
  display: inline-block;
  width: 85%;
}

.QuoteView {
  top: 0px;
  left: 0px;
  position: absolute;
  transition: margin-left 0.75s;
  width: 100vw;
  overflow: hidden;
}

.QuoteView.NoAnimation {
  animation: none !important;
  transition: none !important;
}

.QuoteView.HiddenLeft {
  margin-left: -100vw;
}

.QuoteView.HiddenRight {
  margin-left: 100vw;
}

.GranBackground {
  filter: grayscale() brightness(150%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: inherit;
  object-fit: cover;
}

@media (orientation: portrait) {
  .SolutionIconHolder {
    width: 20%;
  }

  .SolutionInfo {
    position: relative;
    width: 70%;
    max-width: 400px;
  }

  .SolutionBox {
    width: 90%;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 8px;
  }

  .HomePage > .Title > div > img {
    width: 80%;
    max-width: 766px;
  }

  .ClientHeader {
    margin-top: 0px;
  }

  .QuoteBar1 {
    height: 200px;
    margin-top: 5vh;
  }

  .QuoteBar1 span {
    font-size: 17pt;
  }
}

@media only screen and (max-width: 360px) {
  .SolutionText {
    font-size: 0.9em;
  }

  .VerMas {
    font-size: 0.8em;
    padding: 4px 8px;
  }
}


@media only screen and (max-width: 480px) {
  .QuoteBar1 span {
    font-size: 14pt;
  }
}
